<template lang="pug">
  the-instruction(:id="$route.params.id")
</template>

<script>
import TheInstruction from '@/modules/StartPage/components/TheInstruction.vue';

export default {
  components: {
    TheInstruction,
  },
};
</script>
